<template>
  <TheCatcherComponent>
    <EmbeddedStepSwap
      v-if="experiments['experiment.newStepSwap'] === 'withNewStepSwap'"
      :is-focused
      :model
      :price
      :without-left-template
    />
    <StepSwap v-else :is-focused :model :price :without-left-template />
  </TheCatcherComponent>
</template>

<script setup lang="ts">
import type { Price } from '@backmarket/http-api'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'

import TheCatcherComponent from '~/scopes/buyback/components/TheCatcher/TheCatcherComponent.vue'
import EmbeddedStepSwap from '~/scopes/buyback/swap/components/EmbeddedStepSwap/EmbeddedStepSwap.vue'
import StepSwap from '~/scopes/buyback/swap/components/StepSwap/StepSwap.vue'

defineProps<{
  isFocused: boolean
  price: Price
  model?: string
  withoutLeftTemplate?: boolean
}>()

const experiments = useExperiments()
</script>
