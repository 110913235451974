<template>
  <StepLayout :is-focused :tracking="trackingStep" :without-left-template>
    <template #left>
      <slot :name="`guidance-${step.id}`" />

      <RevIllustration
        v-if="step.desktopIllustration"
        alt=""
        class="block max-w-[498px] rounded-[32px] md:min-w-[337px]"
        :height="498"
        :src="step.desktopIllustration"
        :width="498"
      />
    </template>

    <template #right>
      <div class="mb-12 flex items-baseline justify-between">
        <h3 class="heading-1">
          <span v-if="isTitleWithoutEmphasis">
            {{ step.title }}
          </span>
          <FormattedMessage v-else :definition="step.title as I18nDefinition">
            <template #price>
              <span v-if="step.titlePrice" class="bg-static-info-max">
                {{ i18n.price(step.titlePrice) }}
              </span>
            </template>
            <template #emphasis>
              {{
                typeof step.titleEmphasis === 'string' || !step.titleEmphasis
                  ? step.titleEmphasis
                  : i18n(step.titleEmphasis)
              }}
            </template>
          </FormattedMessage>
        </h3>

        <slot :name="`title-${step.id}`" />
      </div>

      <slot :name="`description-${step.id}`" />

      <div class="block md:hidden">
        <slot :name="`guidance-${step.id}`" />
      </div>

      <CardGuidance
        v-if="step.guidance"
        v-bind="step.guidance"
        :class="{ 'hidden md:flex': step.id === 'grades' }"
      />

      <ul class="list-none" :class="stepOptionsClasses(step)">
        <StepOption
          v-for="(option, index) in options"
          :key="option.trackingValue"
          :index
          :is-group-loading
          :is-small="isSmallPicker"
          :option
          :step
          @changed="onOptionChanged"
          @click="onOptionClick"
        />

        <li v-if="shouldDisplayToggleButton" class="mb-12">
          <LargePicker
            :aria-expanded="displayAllItems"
            class="m-auto h-full"
            :index="options.length"
            :is-small="isSmallPicker"
            :label="toggleLabel"
            @click="toggleDisplayAllItems"
          />
        </li>
      </ul>

      <slot :name="`step-end-${step.id}`" />
    </template>
  </StepLayout>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'

import { type GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import type { I18nDefinition } from '@backmarket/nuxt-module-i18n/types'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevIllustration } from '@ds/components/Illustration'

import { type Step } from '../../utils/types'
import CardGuidance from '../CardGuidance/CardGuidance.vue'
import LargePicker from '../LargePicker/LargePicker.vue'
import StepLayout from '../StepLayout/StepLayout.vue'
import StepOption from '../StepOption/StepOption.vue'

import translations from './Step.translations'

const MAX_PICKER_ITEMS = 6

const props = withDefaults(
  defineProps<{
    step: Step
    isFocused?: boolean
    product: GetProductResponse
    withoutLeftTemplate?: boolean
  }>(),
  {
    isFocused: true,
    withoutLeftTemplate: false,
  },
)

const displayAllItems = ref(false)
const isGroupLoading = ref(false)
const i18n = useI18n()

const shouldDisplayToggleButton = computed(() => {
  return props.step.options.length > MAX_PICKER_ITEMS
})

const maximumAmountOfItemsToShow = computed(() => {
  return MAX_PICKER_ITEMS - 1
})

const remainingItemsCount = computed(() => {
  return props.step.options.length - maximumAmountOfItemsToShow.value
})

const options = computed(() => {
  if (shouldDisplayToggleButton.value && !displayAllItems.value) {
    return props.step.options.slice(0, maximumAmountOfItemsToShow.value)
  }

  return props.step.options
})

const trackingTags = computed(() => {
  const hasGoodDeal = props.step.options.some((option) => option.goodDeal)
  const hasSelectedGoodDeal = props.step.options.some(
    (option) => option.goodDeal && option.selected,
  )

  const hasStaffPick =
    props.step.id === 'grades' &&
    !props.step.options.some((option) => option.goodDeal)
  const hasSelectedStaffPick =
    hasStaffPick &&
    props.step.options.some(
      (option) => option.parameters?.grade?.value === 11 && option.selected,
    )

  return {
    // eslint-disable-next-line no-nested-ternary
    tagDisplayed: hasStaffPick
      ? 'staff_pick'
      : hasGoodDeal
        ? 'good_deal'
        : 'no_tag',
    tagSelected: hasSelectedStaffPick || hasSelectedGoodDeal,
  }
})

const trackingStep = computed(() => {
  return {
    trackingTags: trackingTags.value,
    trackingId: props.step.trackingId,
    trackingModel: props.product.model,
  }
})

const toggleLabel = computed(() => {
  return displayAllItems.value
    ? i18n(translations.showLess)
    : i18n(translations.showMore, {
        remaining: remainingItemsCount.value,
      })
})

const isSmallPicker = computed(() => {
  return (props.step.columns ?? 1) > 1
})

const isTitleWithoutEmphasis = computed(() => {
  return typeof props.step.title === 'string'
})

function stepOptionsClasses(step: Step) {
  if (step.columns) {
    return `grid grid-cols-${step.columns} gap-x-12`
  }

  return ''
}
function toggleDisplayAllItems() {
  displayAllItems.value = !displayAllItems.value
}
function onOptionClick() {
  isGroupLoading.value = true
}
function onOptionChanged() {
  isGroupLoading.value = false
}
</script>
