<template>
  <RevTag
    v-if="shouldDisplayYouSave"
    :label="
      i18n(translations.youSaveDisplay, {
        reduction: i18n.price(amount),
      })
    "
    variant="success"
  />
</template>

<script lang="ts" setup>
/**
 * Displays the difference between original price & what the user will pay.
 * In a near future it'll include mobile plan subsidy & trade-in savings.
 *
 * We have a mix of frontend AND backend conditions to display it, and we should change this someday.
 * - We sometimes don't have the original price value
 * - We don't display it in some countries (i.e.: Japan)
 * - We display it only on smartphones.
 * - Backend sends us, in best_offers endpoint, a boolean to let us know if the difference between
 *   the original price and the offer is big enough to display it
 *   @see https://github.com/BackMarket/badoom/blob/main/product_page/listing/views/v3/best_offers.py#L321-L346
 */
import { computed } from 'vue'

import { type Price } from '@backmarket/http-api'
import type { MobilePlanOffer } from '@backmarket/http-api/src/api-specs-b2c-services/mobile-plan/types/mobile-plan-offers'
import type { GetOfferV1Response } from '@backmarket/http-api/src/api-specs-buyback/customer/getOfferV1'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevTag } from '@ds/components/Tag'

import { ProductCategory } from '~/scopes/product/constants'

import { COUNTRIES_WITHOUT_ORIGINAL_PRICE } from '../../Price.constants'

import translations from './YouSave.translations'

const props = defineProps<{
  isDiscountReassuranceDisplayed: boolean
  priceWhenNew?: Price
  price: Price
  categoryId: string
  mobilePlan?: MobilePlanOffer
  buybackOffer: GetOfferV1Response | null
}>()

const i18n = useI18n()
const {
  market: { countryCode },
} = useMarketplace()

const shouldDisplayYouSave = computed(() => {
  return (
    props.isDiscountReassuranceDisplayed &&
    !COUNTRIES_WITHOUT_ORIGINAL_PRICE.includes(countryCode) &&
    !isEmpty(props.priceWhenNew) &&
    props.categoryId === ProductCategory.SMARTPHONE
  )
})

const isSwapActive = computed(() => {
  return props.buybackOffer
})

const amount = computed(() => {
  const priceNew = parseFloat(props.priceWhenNew?.amount ?? '0')

  const savings =
    priceNew -
    parseFloat(props.price.amount) +
    parseFloat(props.mobilePlan?.subsidy.amount ?? '0') +
    (isSwapActive.value
      ? (parseFloat(props.buybackOffer?.listing.price.amount || '') ?? 0)
      : 0)

  const youSavePrice: Price = {
    amount: savings.toString(),
    currency: props.price.currency,
  }

  return youSavePrice
})
</script>
