<template>
  <VisibleByVerizonOfferStep
    v-if="hasPartnerPromoCode(partnerPromoCodes, 'VISIBLE')"
    class="pb-48 pt-56 md:py-0"
    :has-visible-premium-offer
    :model
    :without-left-template
  />

  <RakutenCarouselStep
    v-if="hasPartnerPromoCode(partnerPromoCodes, 'RAKUTEN')"
    class="my-72 md:my-36"
    :model
    :without-left-template
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'

import { hasPartnerPromoCode } from '~/scopes/product/utils/hasPartnerPromoCode'
import { hasVisiblePremium } from '~/scopes/product/utils/hasVisiblePremium'

import RakutenCarouselStep from './components/RakutenCarouselStep/RakutenCarouselStep.vue'
import VisibleByVerizonOfferStep from './components/VisibleByVerizonOfferStep/VisibleByVerizonOfferStep.vue'

const props = defineProps<{
  model?: string
  partnerPromoCodes: GetProductResponse['includedServiceOffers']['partnerPromoCodes']
  withoutLeftTemplate?: boolean
}>()

const hasVisiblePremiumOffer = computed(() => {
  const visibleOffer = props.partnerPromoCodes.find(
    (partnerPromoCodes) => partnerPromoCodes.partnerName === 'VISIBLE',
  )

  if (visibleOffer) {
    return hasVisiblePremium(visibleOffer)
  }

  return false
})
</script>
