<template>
  <StepLayout
    :tracking="{ trackingId: 'rakuten', trackingModel: model }"
    :without-left-template
  >
    <template #left>
      <RakutenCarousel />
    </template>
    <template #right>
      <div class="space-y-16">
        <CarouselHeader
          :link="i18n(translations.learnMoreLink)"
          :title="i18n(translations.headerTitle)"
          :tracking="{
            zone: 'rakuten_product_page_step',
            name: 'learn_more',
          }"
        />
        <RakutenCarousel class="md:hidden" />
        <RakutenCarouselPickers />
      </div>
    </template>
  </StepLayout>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import StepLayout from '../../../StepLayout/StepLayout.vue'
import CarouselHeader from '../CarouselHeader/CarouselHeader.vue'

import translations from './RakutenCarouselStep.translations'
import RakutenCarousel from './components/RakutenCarousel/RakutenCarousel.vue'
import RakutenCarouselPickers from './components/RakutenCarouselPickers/RakutenCarouselPickers.vue'

defineProps<{
  model?: string
  withoutLeftTemplate?: boolean
}>()

const i18n = useI18n()
</script>
