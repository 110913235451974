<template>
  <div>
    <SkinnyBanner v-if="!areImmersiveStoriesEnabled" page="product" />
    <Breadcrumb
      v-if="productResponse && breadcrumbResponse"
      :breads="breadcrumbResponse.breadcrumb"
      class="bg-surface-default-low"
      :class="{ 'hidden md:block': areImmersiveStoriesEnabled }"
      :title="productResponse.titles.raw"
    />

    <ImmersiveStories
      v-if="productResponse && !isOutOfStock && areImmersiveStoriesEnabled"
      class="md:hidden"
      :images="productResponse.images"
      :tracking-category="productResponse.tracking.categoryName"
      :tracking-model="productResponse.model"
    >
      <template #breadcrumb>
        <Breadcrumb
          v-if="breadcrumbResponse"
          :breads="breadcrumbResponse.breadcrumb"
          class="md:hidden"
          :title="productResponse.titles.raw"
        />
      </template>
      <template #above-progress>
        <FavoritesToggle
          class="bg-static-default-low border-action-default-low rounded-full border-1"
          :grade-id="selectedOffer?.grade.value"
          is-rounded
          :offer-type
          :price="selectedOffer?.price"
          :product-uuid="productResponse.productId"
          :tracking-info="
            fromProductTrackingModel(productTracking.product.value)
          "
          zone="details"
        />
      </template>
      <template #title>
        <RevContainer>
          <TitleMobileImmersiveStories
            v-if="price"
            class="pt-8 md:hidden"
            :product="productResponse"
            :rating="ratesResponse"
          />
        </RevContainer>
      </template>
    </ImmersiveStories>

    <RevContainer class="bg-surface-default-low md:hidden">
      <TitleMobileAtc
        v-if="
          productResponse &&
          !isOutOfStock &&
          areImmersiveStoriesEnabled &&
          price
        "
        ref="addToCartMobileButtonVisibilityBeacon"
        :price
        :product="productResponse"
        :rating="ratesResponse"
        :selected-mobile-plan
        :selected-offer
        :show-eco-block="ecoBlockContent.display"
        :tracking="productTracking"
      />
    </RevContainer>

    <RevContainer
      v-if="productResponse"
      class="bg-surface-default-low pb-48 md:pb-56"
      :class="{ 'pb-18 md:pb-48': isCustomizationFunnelEnabled }"
    >
      <OutOfStock
        v-if="isOutOfStock"
        :alternative="outOfStockAlternative"
        :breadcrumb="breadcrumbResponse?.breadcrumb ?? []"
        :is-alternative-loading="outOfStockAlternativeLoading"
        :product="productResponse"
        :ratings="ratesResponse"
      />

      <div
        v-if="!isOutOfStock"
        class="flex flex-col flex-wrap md:flex-row md:flex-nowrap"
        :class="[
          {
            'items-center': isCustomizationFunnelEnabled,
            'justify-start gap-x-24 lg:gap-x-72': areImmersiveStoriesEnabled,
          },
        ]"
      >
        <div
          class="relative w-full max-w-full grow-0 md:w-1/3 lg:w-1/2"
          :class="{
            '': !areImmersiveStoriesEnabled,
            'md:aspect-[2/3] lg:aspect-[5/6]': areImmersiveStoriesEnabled,
          }"
        >
          <div
            class="top-[7rem]"
            :class="{
              'md:sticky md:right-1/2 md:mr-56': !areImmersiveStoriesEnabled,
            }"
          >
            <Gallery
              v-if="!areImmersiveStoriesEnabled"
              :images="productResponse.images"
              :tracking-product-model="productResponse.model"
              tracking-zone="pp_content_carousel"
            />

            <ImmersiveStories
              v-if="areImmersiveStoriesEnabled"
              class="hidden md:block"
              :images="productResponse.images"
              :tracking-category="productResponse.tracking.categoryName"
              :tracking-model="productResponse.model"
            />
          </div>
        </div>

        <div
          class="w-full max-w-full grow-0 md:w-2/3 md:basis-2/3 lg:w-1/2 lg:basis-1/2"
        >
          <TitleDesktop
            v-if="price"
            class="hidden md:block"
            :price
            :product="productResponse"
            :rating="ratesResponse"
            :selected-mobile-plan
            :selected-offer
            :show-eco-block="ecoBlockContent.display"
            :tracking="productTracking"
          />

          <TitleMobile
            v-if="!areImmersiveStoriesEnabled && price"
            class="md:hidden"
            :price
            :product="productResponse"
            :rating="ratesResponse"
            :selected-offer
            :show-eco-block="ecoBlockContent.display"
            :tracking="productTracking"
          />
          <TitleMobileAtc
            v-if="!areImmersiveStoriesEnabled && price"
            ref="addToCartMobileButtonVisibilityBeacon"
            class="md:hidden"
            :price
            :product="productResponse"
            :rating="ratesResponse"
            :selected-mobile-plan
            :selected-offer
            :show-eco-block="ecoBlockContent.display"
            :tracking="productTracking"
          />

          <AdvertisedPayment :selected-offer />
          <AdvertisementChips :product="productResponse" />

          <ReassuranceItems
            v-if="productResponse && selectedOffer"
            :carrier="productResponse.carrier"
            :shipping="selectedOffer.shipping"
            :show-verified-refurbished="productResponse.showVerifiedRefurbished"
            :warranty-delay="selectedOffer.defaultWarrantyDelay"
          />

          <Pickers
            v-if="!isCustomizationFunnelEnabled"
            :pickers="pickersResponse?.pickerGroups ?? []"
          />

          <Details
            v-if="!isCustomizationFunnelEnabled"
            has-swap
            :product="productResponse"
            :product-category
            :selected-offer
            :technical-specifications="technicalSpecificationsResponse"
          />
        </div>
      </div>
    </RevContainer>

    <CheckpointsCarousel
      v-if="productResponse"
      :category-id="productResponse.tracking.categoryId"
      class="pb-48"
    />

    <NoGrade
      v-if="
        pickersResponse &&
        productResponse &&
        !isOutOfStock &&
        isCustomizationFunnelEnabled &&
        productResponse.tracking.categoryId === ProductCategory.SMARTPHONE
      "
      :brand="productResponse.brand ?? ''"
      :is-loading="isPickersResponsePending"
      :model="productResponse.model ?? ''"
      :pickers="pickersResponse"
    />

    <RevContainer
      v-if="
        productResponse &&
        isCustomizationFunnelEnabled &&
        selectedOffer &&
        price
      "
      class="bg-surface-default-low"
    >
      <CustomizationFunnelWrapper
        v-if="pickersResponse"
        :breadcrumb="breadcrumbResponse"
        :pickers-response
        :pickers-services="servicesPickersResponse"
        :price
        :price-without-subsidies
        :price-without-trade-in
        :product="productResponse"
        :product-category
        :product-tracking
        :selected-mobile-plan
        :selected-offer
        :technical-specifications="technicalSpecificationsResponse"
        @hide="handleHideTopBar"
        @show="handleShowTopBar"
      />
    </RevContainer>

    <RevContainer
      v-if="productResponse && !errorReco"
      class="mt-56"
      data-id="reco-section"
    >
      <Recommendations
        :product="productResponse"
        :selected-offer
        @bundle-add-to-cart="onBundleAddToCart"
        @recommendations-error="onRecommendationsError"
        @recommendations-loaded="onRecommendationsLoaded"
      />
    </RevContainer>

    <RevContainer v-if="productResponse && ratesResponse?.count" class="mb-56">
      <ReviewsBlock
        id="reviews"
        :link="reviewsPageLink"
        :product-title="productResponse.titles.raw"
        :rating="ratesResponse"
        :reviews="reviews.list"
        :reviews-pending="reviews.isPending"
        :tracking-info="productTracking.product.value"
      />
    </RevContainer>

    <div class="bg-surface-default-hi">
      <RevContainer>
        <PopularSearches
          v-if="tagsResponse"
          class="my-32 md:my-72"
          :tags="tagsResponse"
        />
      </RevContainer>

      <EcoBlock :content="ecoBlockContent" data-id="eco-block" />

      <RevContainer>
        <SeoContent
          v-if="productResponse && technicalSpecificationsResponse"
          class="my-32 md:my-72"
          data-id="seo-technical-specs"
          :description="productResponse.seo.description ?? ''"
          :model="productResponse.model"
          :technical-specifications="technicalSpecificationsResponse"
          :title="productResponse.titles.raw"
        >
          <ScoreCardContainer
            class="pb-24"
            :productModel="productResponse.model"
            :scores="productResponse.scores"
          />
        </SeoContent>

        <Articles />
        <AboutUs v-if="shouldShowAboutUs" />
        <TrustList class="md:my-40" data-qa="product-reassurance" />
        <TrustCarousel class="my-32 md:my-56" />
      </RevContainer>
    </div>

    <ClientOnly v-if="productResponse">
      <SwapModalWrapper
        v-if="productResponse.isTradeInEligible"
        :datalayer-category="productCategory"
        :initialPayload="swapModalStore.initialPayload"
        :modal-name="BUYBACK_MODAL_NAMES.SWAP_MIX_AND_MATCH"
        :price="discountedPrice"
        zone="swap_pp_block"
      />

      <SwapModalWrapper
        v-if="productResponse.isTradeInEligible"
        :datalayer-category="productCategory"
        intro="atc"
        :modal-name="BUYBACK_MODAL_NAMES.SWAP_ATC"
        :price="discountedPrice"
        :redirect-on-close-target="CHECKOUT.CART"
        zone="swap_pp_atc"
        @continue="handleContinue"
      />

      <TradeInModal v-if="showTradeInModal" />

      <VisibleByVerizonDrawer
        v-if="
          hasPartnerPromoCode(
            productResponse.includedServiceOffers.partnerPromoCodes,
            'VISIBLE',
          ) && visiblePartnerPromoCode
        "
        :promo-code="visiblePartnerPromoCode"
      />

      <AddToCartModal
        v-if="selectedOffer && selectedOffer.offerLegacyId"
        :additional-listings
        :listing-id="selectedOffer.offerLegacyId"
        :title="productResponse.titles.raw"
        @close="onAddToCartModalClose"
      />

      <StickyBarTop
        v-if="
          !isOutOfStock &&
          productResponse &&
          selectedOffer &&
          price &&
          !isCustomizationFunnelEnabled
        "
        :price
        :product="productResponse"
        :selected-mobile-plan
        :selected-offer
        :title="productResponse.titles.raw"
        :tracking="productTracking"
      />

      <StickyBarBottom
        v-if="enableStickyBarDisplay"
        :price="price!"
        :product="productResponse"
        :selected-mobile-plan
        :selected-offer="selectedOffer!"
        :show-bar="!isAddToCartButtonMobileInViewport"
        :tracking="productTracking"
      />
    </ClientOnly>
  </div>
</template>

<script lang="ts" setup>
import { useRoute, useRuntimeConfig } from '#imports'
import { computed, onMounted, ref, watch } from 'vue'

import { getEcoBlockDefaultValue } from '@backmarket/http-api/src/api-specs-navigation-experience/product/eco-block'
import { useBuybackOffer } from '@backmarket/nuxt-layer-buyback/composables/buybackOffer/useBuybackOffer'
import { logDesync } from '@backmarket/nuxt-layer-recommendation/helpers.ts'
import type { Product } from '@backmarket/nuxt-layer-recommendation/models/product'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { RevContainer } from '@ds/components/Container'
import { openModal } from '@ds/components/ModalBase'
import { useElementVisibility } from '@vueuse/core'

import { useRouteLocationWithLocale } from '~/composables/useRouteLocationWithLocale'
import SkinnyBanner from '~/scopes/branding/components/SkinnyBanner/SkinnyBanner.vue'
import { MODAL_NAMES as BUYBACK_MODAL_NAMES } from '~/scopes/buyback/constants'
import SwapModalWrapper from '~/scopes/buyback/swap/components/SwapModalWrapper/SwapModalWrapper.vue'
import { useSwapModalStore } from '~/scopes/buyback/swap/stores/useSwapModalStore'
import { CHECKOUT } from '~/scopes/checkout/routes-names'
import FavoritesToggle from '~/scopes/dashboard/favorites/toggle/FavoritesToggle.vue'
import { fromProductTrackingModel } from '~/scopes/dashboard/favorites/utils/FavoriteTrackingModel'
import Articles from '~/scopes/master-pp/components/Articles/Articles.vue'
import { useMasterPPHead } from '~/scopes/master-pp/composables/useMasterPPHead'
import TitleMobileImmersiveStories from '~/scopes/product/components/ImmersiveStories/components/Title/TitleMobileImmersiveStories.vue'
import TrustList from '~/scopes/reassurance/components/TrustList/TrustList.vue'
import ReviewsBlock from '~/scopes/reviews/reviews-display/components/ReviewsBlock/ReviewsBlock.vue'
import { ROUTES as REVIEWS_ROUTES } from '~/scopes/reviews/route-names'

import ScoreCardContainer from '../../master-pp/components/ScoreCard/ScoreCardContainer.vue'
import AboutUs from '../components/AboutUs/AboutUs.vue'
import AddToCartModal from '../components/AddToCartModal/AddToCartModal.vue'
import AdvertisedPayment from '../components/AdvertisedPayment/AdvertisedPayment.vue'
import AdvertisementChips from '../components/AdvertisementChips/AdvertisementChips.vue'
import Breadcrumb from '../components/Breadcrumb/Breadcrumb.vue'
import CheckpointsCarousel from '../components/CheckpointsCarousel/CheckpointsCarousel.vue'
import VisibleByVerizonDrawer from '../components/CustomizationFunnel/components/VisibleByVerizonDrawer/VisibleByVerizonDrawer.vue'
import CustomizationFunnelWrapper from '../components/CustomizationFunnelWrapper/CustomizationFunnelWrapper.vue'
import Details from '../components/Details/Details.vue'
import EcoBlock from '../components/EcoBlock/EcoBlock.vue'
import Gallery from '../components/Gallery/Gallery.vue'
import ImmersiveStories from '../components/ImmersiveStories/ImmersiveStories.vue'
import NoGrade from '../components/NoGrade/NoGrade.vue'
import OutOfStock from '../components/OutOfStock/OutOfStock.vue'
import Pickers from '../components/Pickers/Pickers.vue'
import PopularSearches from '../components/PopularSearches/PopularSearches.vue'
import ReassuranceItems from '../components/ReassuranceItems/ReassuranceItems.vue'
import Recommendations from '../components/Recommendations/Recommendations.vue'
import SeoContent from '../components/SeoContent/SeoContent.vue'
import StickyBarBottom from '../components/StickyBarBottom/StickyBarBottom.vue'
import StickyBarTop from '../components/StickyBarTop/StickyBarTop.vue'
import TitleDesktop from '../components/Title/TitleDesktop.vue'
import TitleMobile from '../components/Title/TitleMobile.vue'
import TitleMobileAtc from '../components/Title/TitleMobileAtc.vue'
import TradeInModal from '../components/TradeIn/components/TradeInModal/TradeInModal.vue'
import TrustCarousel from '../components/TrustCarousel/TrustCarousel.vue'
import { useAddGradeInUrl } from '../composables/useAddGradeInUrl'
import { useGradeFallback } from '../composables/useGradeFallback'
import { useInvalidGradeFallback } from '../composables/useInvalidGradeFallback'
import { useProductHead } from '../composables/useProductHead'
import { useProductPrice } from '../composables/useProductPrice'
import { useProductRematch } from '../composables/useProductRematch'
import { useProductRequests } from '../composables/useProductRequests'
import { useProductTracking } from '../composables/useProductTracking'
import { useRedirectionToQueryParamsURL } from '../composables/useRedirectionToQueryParamsURL'
import { useUrlParams } from '../composables/useUrlParams'
import { MODAL_NAMES, ProductCategory } from '../constants'
import { hasPartnerPromoCode } from '../utils/hasPartnerPromoCode'

const route = useRoute()
const marketPlace = useMarketplace()
const experiments = useExperiments()

const {
  FF_PRODUCT_PAGE_TRADE_IN_CHIP_JP = '',
  FF_ENABLE_COUNTRIES_MASTER_PP_ARTICLES = '',
  FF_ENABLE_MIX_AND_MATCH_CATEGORIES = '',
  FF_ENABLE_MIX_AND_MATCH_EVERYWHERE = false,
} = useRuntimeConfig().public
const i18n = useI18n()

const {
  grade: urlGrade,
  offerType,
  productId,
  mobilePlan,
  withNoGrade,
} = useUrlParams()
const resolveLocalizedRoute = useRouteLocationWithLocale()
const additionalListings = ref<Array<string>>([])
const outOfStockAlternativeLoading = ref(true)
const outOfStockAlternative = ref<Product | null>(null)
const errorReco = ref(false)
// This is used to check if the add to cart button is in the viewport
// on mobile to hide the sticky bar bottom when it is
const addToCartMobileButtonVisibilityBeacon = ref<HTMLDivElement | null>(null)
const isAddToCartButtonMobileInViewport = useElementVisibility(
  addToCartMobileButtonVisibilityBeacon,
)

const {
  productResponse,
  breadcrumbResponse,
  technicalSpecificationsResponse,
  tagsResponse,
  ecoBlockResponse,
  pickersResponse,
  servicesPickersResponse,
  isPickersResponsePending,
  isServicesPickersResponsePending,
  ratesResponse,
  reviewsResponse,
  isReviewsResponsePending,
  isOutOfStock,
  selectedOffer,
  callServicesPickers,
} = await useProductRequests(
  productId,
  urlGrade,
  offerType,
  mobilePlan,
  withNoGrade,
)

const swapModalStore = useSwapModalStore()

const showTradeInModal = computed(() => {
  return (
    FF_PRODUCT_PAGE_TRADE_IN_CHIP_JP.includes(marketPlace.market.countryCode) ||
    productResponse.value?.isTradeInEligible
  )
})

const shouldShowAboutUs = computed(
  () =>
    !FF_ENABLE_COUNTRIES_MASTER_PP_ARTICLES.includes(
      marketPlace.market.countryCode,
    ),
)

const reviews = computed(() => ({
  isPending: isReviewsResponsePending.value,
  list: reviewsResponse.value ? reviewsResponse.value.results : [],
}))

const reviewsPageLink = resolveLocalizedRoute({
  name: REVIEWS_ROUTES.PAGES.PRODUCT,
  params: route.params,
})

const productCategory = computed(() => {
  return (
    (productResponse.value !== null &&
      productResponse.value.tracking &&
      productResponse.value.tracking.categoryName) ||
    ''
  )
})

const ecoBlockContent = computed(() => {
  return ecoBlockResponse.value ?? getEcoBlockDefaultValue
})

const isCustomizationFunnelEnabled = computed(() => {
  if (isOutOfStock.value) {
    return false
  }

  if (
    productResponse.value &&
    (FF_ENABLE_MIX_AND_MATCH_EVERYWHERE ||
      FF_ENABLE_MIX_AND_MATCH_CATEGORIES.split(',').includes(
        productResponse.value.tracking.categoryId,
      ))
  ) {
    return true
  }

  // The rest is disabled
  return false
})

const areImmersiveStoriesEnabled = computed(() => {
  return (
    (experiments['experiment.ppImmersiveStories'] === 'withImmersiveStories' ||
      route.query?.['experiment.ppImmersiveStories'] ===
        'withImmersiveStories') &&
    productResponse.value?.tracking.categoryId === ProductCategory.SMARTPHONE
  )
})

const selectedMobilePlan = computed(() => {
  return (
    servicesPickersResponse.value?.pickerGroups
      ?.find(({ id }) => id === 'mobile_plan')
      ?.items?.find(({ selected }) => !!selected)?.mobilePlanOffer ?? undefined
  )
})

const visiblePartnerPromoCode = computed(() => {
  return productResponse.value?.includedServiceOffers.partnerPromoCodes.find(
    (partnerPromoCode) => partnerPromoCode.partnerName === 'VISIBLE',
  )
})

useProductRematch(productResponse)
useInvalidGradeFallback()
useGradeFallback(pickersResponse)
useAddGradeInUrl(selectedOffer)
useRedirectionToQueryParamsURL()
useProductHead(productResponse, selectedOffer, ratesResponse, isOutOfStock)
useMasterPPHead(productResponse)
const productTracking = useProductTracking(
  productResponse,
  selectedOffer,
  ratesResponse,
  pickersResponse,
  isPickersResponsePending,
  servicesPickersResponse,
  isServicesPickersResponsePending,
)
const { buybackOffer, getDiscountedPrice } = useBuybackOffer()
const { price, priceWithoutSubsidies, priceWithoutTradeIn } = useProductPrice({
  pickers: servicesPickersResponse,
  selectedOffer,
})

const discountedPrice = computed(() =>
  priceWithoutSubsidies.value
    ? i18n.price(getDiscountedPrice(priceWithoutSubsidies.value))
    : '',
)

function onAddToCartModalClose() {
  additionalListings.value = []
}
function onBundleAddToCart(listings: Array<string>) {
  additionalListings.value = listings
}

function onRecommendationsLoaded(products: Array<Product>) {
  outOfStockAlternative.value = products[0]
  outOfStockAlternativeLoading.value = false
}

function onRecommendationsError() {
  errorReco.value = true
  outOfStockAlternativeLoading.value = false
}

function handleContinue() {
  setTimeout(() => {
    openModal(MODAL_NAMES.ADD_TO_CART)
  })
}
function handleShowTopBar() {
  const header = document.getElementById('header')

  if (header && header !== null) {
    header.style.visibility = 'hidden'
  }
}
function handleHideTopBar() {
  const header = document.getElementById('header')

  if (header && header !== null) {
    header.style.visibility = 'visible'
  }
}

onMounted(() => {
  if (productResponse.value?.productId) {
    logDesync({
      id: productResponse.value.productId,
      isOutOfStock: isOutOfStock.value,
      price: selectedOffer.value?.price?.amount,
    })
  }
})

watch([buybackOffer], () => {
  callServicesPickers()
})

const enableStickyBarDisplay = computed(() => {
  return Boolean(
    !isOutOfStock.value &&
      productResponse.value &&
      selectedOffer.value &&
      price.value,
  )
})
</script>

<style>
/* On mobile, we need to add some padding to be able to see everything in the footer due to the sticky add to cart bar */
@media only screen and (max-width: 767px) {
  #main-footer {
    padding-bottom: 100px !important;
  }
}
</style>
