<template>
  <AcceptedOffer
    v-if="buybackOffer"
    :name="buybackOffer?.product.shortTitle"
    :price="buybackOffer?.listing.price"
    :tier="buybackOffer?.tier"
    @remove="deleteBuybackOffer"
  />
  <NoOfferV2 v-else :model="model || ''" />
</template>

<script setup lang="ts">
import { useBuybackOffer } from '@backmarket/nuxt-layer-buyback/composables/buybackOffer/useBuybackOffer'

import AcceptedOffer from '../AcceptedOffer/AcceptedOffer.vue'
import NoOfferV2 from '../NoOfferV2/NoOfferV2.vue'

defineProps<{
  model?: string
}>()

const { deleteBuybackOffer, buybackOffer } = useBuybackOffer()
</script>
